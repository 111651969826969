import React, { Component } from 'react';
import Menu from './cmdMenu'
import cmd from '../photos/cmd.PNG';

class CMD extends Component {
	constructor(props) {
		super(props);
		
		this.bringToFront = this.bringToFront.bind(this);
		this.closeApp = this.closeApp.bind(this);
	}

	closeApp() {
		document.getElementById('cmdApp').style.zIndex = 1;
        document.getElementById('cmdApp').style.display = 'none';
    }
	
	bringToFront() {
		
		document.getElementById('cmdApp').style.zIndex = 2;
		document.getElementById('streamPageApp').style.zIndex = 1;
		document.getElementById('storePageApp').style.zIndex = 1;
		document.getElementById('aboutPageApp').style.zIndex = 1;
		document.getElementById('resourcesApp').style.zIndex = 1;
		document.getElementById('contactPageApp').style.zIndex = 1;
	}

	render() { 
		return (
            <div 
				id="cmdApp" 
				onClick={this.bringToFront}
			>
				<div id="cmdtitlearea">
					<div id="cmd-img">
						<img id="cmdApp-img" src={cmd} alt="App Icon"/>
					</div>
					<div id="cmd-title"> 
						<b> Command Prompt </b>
					</div>
					<div className='closeButton' onClick={this.closeApp}>X</div>
				</div>
				<div id="cmd-program">
					Microsoft Windows [Version 10.0.10240] <br />
					(c) 2015 Microsoft Corporation. All rights reserved.
				</div>
				<Menu />
			</div>
        );
    }
}
 
export default CMD;