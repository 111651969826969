import React, { Component } from 'react';

class Store extends Component {
	constructor(props) {
		super(props);

		this.bringToFront = this.bringToFront.bind(this);
		this.closeApp = this.closeApp.bind(this);
	}

	bringToFront() {
		document.getElementById('cmdApp').style.zIndex = 1;
		document.getElementById('streamPageApp').style.zIndex = 1;
		document.getElementById('storePageApp').style.zIndex = 2;
		document.getElementById('aboutPageApp').style.zIndex = 1;
		document.getElementById('resourcesApp').style.zIndex = 1;
		document.getElementById('contactPageApp').style.zIndex = 1;
	}

	closeApp() {
		document.getElementById('storePageApp').style.zIndex = 1;
        document.getElementById('storePageApp').style.display = 'none';
    }

    render() { 
        return (
            <div id="storePageApp" onClick={this.bringToFront}>
				<div id="cmdtitlearea">
					<div id="storePage-title"> 
						<b> MFKN Store </b>
					</div>
					<div className='closeButton' onClick={this.closeApp}>X</div>
				</div>
				<div id="storePageBody">
					<div id='collection-component-1675208705560' draggable="false"></div>
				</div>
				<div id="storePageBody2">
					<pre>
					 @@@@@@@   @@@@@@   @@@@@@@@@@   @@@  @@@  @@@   @@@@@@@@      @@@@@@    @@@@@@    @@@@@@   @@@  @@@  @@@  <br/>
					@@@@@@@@  @@@@@@@@  @@@@@@@@@@@  @@@  @@@@ @@@  @@@@@@@@@     @@@@@@@   @@@@@@@@  @@@@@@@@  @@@@ @@@  @@@  <br/>
					!@@       @@!  @@@  @@! @@! @@!  @@!  @@!@!@@@  !@@           !@@       @@!  @@@  @@!  @@@  @@!@!@@@  @@!  <br/>
					!@!       !@!  @!@  !@! !@! !@!  !@!  !@!!@!@!  !@!           !@!       !@!  @!@  !@!  @!@  !@!!@!@!  !@   <br/>
					!@!       @!@  !@!  @!! !!@ @!@  !!@  @!@ !!@!  !@! @!@!@     !!@@!!    @!@  !@!  @!@  !@!  @!@ !!@!  @!@  <br/>
					!!!       !@!  !!!  !@!   ! !@!  !!!  !@!  !!!  !!! !!@!!      !!@!!!   !@!  !!!  !@!  !!!  !@!  !!!  !!!  <br/>
					:!!       !!:  !!!  !!:     !!:  !!:  !!:  !!!  :!!   !!:          !:!  !!:  !!!  !!:  !!!  !!:  !!!       <br/>
					:!:       :!:  !:!  :!:     :!:  :!:  :!:  !:!  :!:   !::         !:!   :!:  !:!  :!:  !:!  :!:  !:!  :!:  <br/>
					&nbsp;::: :::  ::::: ::  :::     ::    ::   ::   ::   ::: ::::     :::: ::   ::::: ::  ::::: ::   ::   ::   ::   <br/>
					&nbsp;:: :: :   : :  :    :      :    :    ::    :    :: :: :      :: : :     : :  :    : :  :   ::    :   :::   <br/>
					</pre>
				</div>
			</div>
        );
    }
}
 
export default Store;