import React, { Component } from 'react';
import { closeScreen} from './helperFunctions';
import CMD from './cmdApp';
import Stream from './streamPageApp';
import Store from './storePageApp';
import About from './aboutPageApp';
import Resources from './resourcesApp'
import Contact from './contactPageApp';
import amfknk from '../photos/amfknk.png';
import mfkn from '../photos/mfknSticker.png';
import dbz from '../photos/dbzsticker.png';
import walltitan from '../photos/walltitan.png';
import madara from '../photos/madara.png';
import kaneki from '../photos/kaneki.png';
import compBorder from '../photos/comp_outerborder.png'
import myComp from '../photos/mycomp.png';
import recycle from '../photos/recycle.png';
import taskbar from '../photos/taskbar.png';
import timesticker from '../photos/timesticker.png';
import lol from '../photos/lol.png';
import smiley from '../photos/smiley.png';
import postit from '../photos/postit.png';
import postit2 from '../photos/powerbuttonpostit.png';
import power from '../photos/power.png';
import clippy1png from '../photos/clippy1.png'
import clippy2png from '../photos/clippy2.png'
import clippy3png from '../photos/clippy3.png'

class Border extends Component {
    constructor(props) {
		super(props);

		this.loadApp = this.loadApp.bind(this);
	}

    componentDidMount() {
        closeScreen();
        
        if (!localStorage.getItem('visitedBefore')) {
            // Display the first Clippy image on the first visit
            document.getElementById('clippy1').style.display = 'block';
        }
    }

    loadApp() {
        document.getElementById("cmdApp").style.display = "block";
        if (!localStorage.getItem('visitedBefore')) {
            document.getElementById("clippy2").style.display = "none";
            document.getElementById("clippy3").style.display = "block";
        }
    }

    render() { 
        return (
            <div className='screen'>
                <div id='compBorder'>
                    <img className="compBordersticker" src={compBorder} alt="compBorder" draggable="false"></img>
                </div>

                <div id='clippy1'>
                    <img className="clippyimg" src={clippy1png} alt="clippy1png" draggable="false"></img>
                </div>

                <div id='clippy2'>
                    <img className="clippyimg" src={clippy2png} alt="clippy2png" draggable="false"></img>
                </div>

                <div id='clippy3'>
                    <img className="clippyimg" src={clippy3png} alt="clippy3png" draggable="false"></img>
                </div>

                <div id="mfknSticker1">
                    <img className="sticker1" src={mfkn} alt='sticker' draggable="false"></img>
                </div>

                <div id="mfknSticker2">
                    <img className="sticker1" src={mfkn} alt='sticker' draggable="false"></img>
                </div>

                <div id="mfknSticker3">
                    <img className="sticker1" src={amfknk} alt='sticker' draggable="false"></img>
                </div>

                <div id="dbzsticker">
                    <img className="dbzsticker" src={dbz} alt='sticker' draggable="false"></img>
                </div>

                <div id="walltitansticker">
                    <img className="walltitansticker" src={walltitan} alt='sticker' draggable="false"></img>
                </div>

                <div id="madara">
                    <img className="madara" src={madara} alt='sticker' draggable="false"></img>
                </div>

                <div id="kaneki">
                    <img className="kaneki" src={kaneki} alt='sticker' draggable="false"></img>
                </div>

                <div id="smiley">
                    <img className="smiley" src={smiley} alt='sticker' draggable="false"></img>
                </div>

                <div id="postit">
                    <img className="postit" src={postit} alt='sticker' draggable="false"></img>
                </div>

                <div id="postit2">
                    <img className="postit" src={postit2} alt='sticker' draggable="false"></img>
                </div>

                <div id="powerbutton" onClick={closeScreen}>
                    <img className="powerImage" src={power} alt='sticker' draggable="false"></img>
                </div>
                <div id="powerbuttonled"></div>

                <div id="screenIsOff">
                    <div id="screenIsOffAnimation"></div>
                </div>
                <div id="screenIsOn"></div>
                
                <div id='innerBorder'>
                    <div id="flicker"></div>
                    <canvas id='matrixRain'></canvas>
                    <CMD/>
                    <Stream/>
                    <Store/>
                    <About/>
                    <Resources/>
                    <Contact/>
                    <div id='recycleBin'>
                        <img className="recycleBinsticker" src={recycle} alt="recycle" draggable="false"></img>
                    </div>
                    <div id="recycleBinText">Recycle Bin</div>
                    <div id='myComp'>
                        <img className="myCompsticker" src={myComp} alt="myComp" draggable="false"></img>
                    </div>
                    <div id="myCompText">My Computer</div>
                    <div id="lol">
                        <img className="lolsticker" src={lol} alt="lol" draggable="false"></img>
                    </div>
                    <div id="loltext">League of<br/>Legends</div>
                    <div id='cmdIconOnClick' onClick={this.loadApp}></div>
                    <div id="cmdIcon">C:\_</div>
                    <div id="cmdIconText">Command <br /> Prompt</div>
                    <div id="taskbar">
                        <div id="timeSticker">
                            <img className="timeSticker" src={timesticker} alt="timesticker" draggable="false"></img>
                        </div>
                            <img className="taskbarsticker" src={taskbar} alt="taskbar" draggable="false"></img>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Border;