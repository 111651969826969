import axios from 'axios';
import React, { Component } from 'react';

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = { 
            fullname: '',
            email: '',
            message: '',
            error: null
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.bringToFront = this.bringToFront.bind(this);
        this.closeApp = this.closeApp.bind(this);
    }

    handleFormSubmit(event, state) {
        event.preventDefault();
        axios({
            method: 'post',
            url: 'https://formspree.io/f/xlevqlqo',
            data: this.state
        })
        .then(result => {
            this.resetForm();
        })
        .catch(error => this.setState({ error: error.message }));
    }

    resetForm(){
        document.getElementById('thankYouDiv').style.display = 'block';
        this.setState({fullname: '', email: '', message: ''});
    }

    bringToFront() {
        document.getElementById('cmdApp').style.zIndex = 1;
		document.getElementById('streamPageApp').style.zIndex = 1;
		document.getElementById('storePageApp').style.zIndex = 1;
		document.getElementById('aboutPageApp').style.zIndex = 1;
        document.getElementById('resourcesApp').style.zIndex = 1;
		document.getElementById('contactPageApp').style.zIndex = 2;
	}

    closeApp() {
        document.getElementById('contactPageApp').style.zIndex = 1;
        document.getElementById('contactPageApp').style.display = 'none';
    }

    closeThankYou() {
        document.getElementById('thankYouDiv').style.display = 'none';
    }

    render() { 
        return (
            <div id='contactPageApp' onClick={this.bringToFront}>
                <div id="cmdtitlearea">
					<div id="contactPage-title"> 
						<b> Contact Form </b>
					</div>
                    <div className='closeButton' onClick={this.closeApp}>X</div>    
				</div>
                <div id="contactForm">
                    <form  onSubmit={e => {this.handleFormSubmit(e, this.state)}}>
                        <input type="text" id="fullname" name="fullname" placeholder="Your full name..." 
                        value={this.state.fullname} onChange={e => this.setState({fullname: e.target.value})}/>
                            
                        <input type="email" id="email" name="email" placeholder="Your email address..." 
                        value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>
                            
                        <textarea id="message" name="message" placeholder="Message goes here..." 
                        value={this.state.message} onChange={e => this.setState({message: e.target.value})}></textarea>
                            
                        <button type="submit" id="submit">Submit</button>
                    </form>
                </div>
                <div id="thankYouDiv">
                    <div id="thankYouDivTitle"> 
                        <div className='closeButton' onClick={this.closeThankYou}>X</div>
                    </div>
                    <div id="thankYouMessage">
                        Thank You! <br />
                        Your message has been sent.
                    </div> 
                </div>
            </div>
        );
    }
}
 
export default Contact;