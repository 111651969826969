import { useEffect } from 'react';
import './App.css';
import Border from './components/screenBorder';
import { Analytics } from '@vercel/analytics/react';
import { loadMenu } from './components/helperFunctions';

function App() {
  useEffect(() => {
    function handleKeyDown(e) {
        loadMenu(e);
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanUp() {
        document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const isMobileOrTablet = window.innerWidth < 768; // Adjust this threshold as needed

  return (
    isMobileOrTablet ? window.location.href = 'https://mobile.mfkn.org/' :
    <div className="App" tabIndex={0}>
        <Border />
        <Analytics/>
    </div>
  );
}

export default App;
