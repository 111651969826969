import React, { Component } from 'react';
import mfkn from '../photos/mfkn.png';

class Stream extends Component {
	constructor(props) {
		super(props);

		this.bringToFront = this.bringToFront.bind(this);
		this.closeApp = this.closeApp.bind(this);
	}

	bringToFront() {
		document.getElementById('cmdApp').style.zIndex = 1;
		document.getElementById('streamPageApp').style.zIndex = 2;
		document.getElementById('storePageApp').style.zIndex = 1;
		document.getElementById('aboutPageApp').style.zIndex = 1;
		document.getElementById('resourcesApp').style.zIndex = 1;
		document.getElementById('contactPageApp').style.zIndex = 1;
	}

	closeApp() {
		document.getElementById('streamPageApp').style.zIndex = 1;
        document.getElementById('streamPageApp').style.display = 'none';
    }

    render() { 
        return (
            <div id="streamPageApp" onClick={this.bringToFront}>
				<div id="streamTitleArea">
					<div id="streamPageApp-img-div">
							<img id="streamPageApp-img" src={mfkn} alt="App Icon"/>
						</div>
					<div id="streamPageApp-title"> 
						<b> MFKN_AK Stream </b>
					</div>
					<div className='closeButton' onClick={this.closeApp}>X</div>
				</div>
				<div id="twitch-embed">
					<iframe
						title="MFKN_AK Stream"
						src="https://player.kick.com/mfkn_ak"
						top="10%"
						height="99.5%"
						width="99.5%"
						allowFullScreen={false}>
					</iframe>
				</div>
			</div>
        );
    }
}
 
export default Stream;