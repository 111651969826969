export var menu = 0;
var key = 0;
var screen = 1;
var glitchCount = 0;
var count = 0;
var english = "10⋗ΣΠΣ木৲XƆ⋏⋌とぐぃムニウゾ";

export function matrixRain() {
    menu += 1;
    var c = document.getElementById("matrixRain");
    var ctx = c.getContext("2d");

    //canvas full screen
    c.height = window.innerHeight;
    c.width = window.innerWidth;

    //converting the string into an array of single characters
    english = english.split("");

    var font_size = 12;
    var columns = c.width/font_size; //number of columns for the rain

    //an array of drops - one per column
    var drops = [];

    //x below is the x coordinate
    //1 = y co-ordinate of the drop(same for every drop initially)
    for(var x = 0; x < columns; x++)
        drops[x] = 1; 

    //drawing the characters
    function draw()
    {
        //Black BG for the canvas
        //translucent BG to show trail
        ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
        ctx.fillRect(0, 0, c.width, c.height);

        ctx.fillStyle = "#E21330"; //green text #0F0 //red #cf102b
        ctx.font = font_size + "px arial";
    
        //looping over drops
        for(var i = 0; i < drops.length; i++)
        {
            //a random character to print
            var text = english[Math.floor(Math.random()*english.length)];
            //x = i*font_size, y = value of drops[i]*font_size
            ctx.fillText(text, i*font_size, drops[i]*font_size);
    
            //sending the drop back to the top randomly after it has crossed the screen
            //adding a randomness to the reset to make the drops scattered on the Y axis
            if(drops[i]*font_size > c.height && Math.random() > 0.975)
            drops[i] = 0;
    
            //incrementing Y coordinate
            drops[i]++;
        }
    }
    setInterval(draw, 60);
}

export function loadMenu(event) {
    var cmdStatus = document.getElementById("cmdApp").style.display;
    if (menu === 0  && event.key === 'Enter' && cmdStatus === "block") {
        if(!localStorage.getItem('visitedBefore')) {
            triggerEvaporateEffect();

            // Set a local storage value to indicate the user has visited
            localStorage.setItem('visitedBefore', 'true');
        }

        document.getElementById('menu1').style.width = '23.5%';
        document.getElementById('menu2').style.display = 'block';
        document.getElementById('menu3').style.display = 'block';
        document.getElementById('menu4').style.display = 'block';
        document.getElementById('menu5').style.display = 'block';
        document.getElementById('menu1').style.zIndex = '3';
        document.getElementById('menu2').style.zIndex = '3';
        document.getElementById('menu3').style.zIndex = '3';
        document.getElementById('menu4').style.zIndex = '3';
        document.getElementById('menu5').style.zIndex = '3';
        document.getElementById('menu1').innerHTML = 'MFKN_AK Stream';
        document.getElementById('menu2').innerHTML = 'MFKN Store';
        document.getElementById('menu3').innerHTML = 'About MFKN';
        document.getElementById('menu4').innerHTML = 'Resources';
        document.getElementById('menu5').innerHTML = 'Contact Page';
        document.getElementById('cursor').style.left = '57%';
        matrixRain();

        flashWindows(4);

        // Add a delay before displaying the streamPageApp
        setTimeout(() => {
            document.getElementById('streamPageApp').style.display = 'block'
            document.getElementById('streamPageApp').style.zIndex = '2';
        }, 6000); // 1000 milliseconds = 1 second delay
    }

    else if (menu === 1 && event.keyCode === 13) {
        if(key === 0) {
            document.getElementById('streamPageApp').style.display = 'block';
            document.getElementById('streamPageApp').style.zIndex = '2';
        }
        else if(key === 1) {
            document.getElementById('storePageApp').style.display = 'block';
            document.getElementById('storePageApp').style.zIndex = '2';
            document.getElementById('storePageApp').style.opacity = '1';
        }
        else if(key === 2) {
            document.getElementById('aboutPageApp').style.display = 'block';
            document.getElementById('aboutPageApp').style.zIndex = '2';
        }
        else if(key === 3) {
            document.getElementById('resourcesApp').style.display = 'block';
            document.getElementById('resourcesApp').style.zIndex = '2';
        }
        else if(key === 4) {
            document.getElementById('contactPageApp').style.display = 'block';
            document.getElementById('contactPageApp').style.zIndex = '2';
        }
    }

    else if (menu === 1 && event.keyCode === 40) {
        if(key === 4) {
            key--;
        }
        key += 1;
        cursorMove();
        if(key === 0) {
            document.getElementById('cursor').style.left = '57%';
            document.getElementById('menu1').style.borderColor = 'white';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'black';
        }
        
        else if(key === 1) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'white';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'black';
        }
    
        else if(key === 2) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'white';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'black';
        }
    
        else if(key === 3) {
            document.getElementById('cursor').style.left = '54%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'white';
            document.getElementById('menu5').style.borderColor = 'black';
        }

        else if(key === 4) {
            document.getElementById('cursor').style.left = '54%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'white';
        }
    }

    else if (menu === 1 && event.keyCode === 38) {
        if(key === 0) {
            key++;
        }
        key -= 1;
        cursorMove();
        if(key === 0) {
            document.getElementById('cursor').style.left = '57%';
            document.getElementById('menu1').style.borderColor = 'white';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'black';
        }
        
        else if(key === 1) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'white';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'black';
        }
    
        else if(key === 2) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'white';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'black';
        }
    
        else if(key === 3) {
            document.getElementById('cursor').style.left = '54%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'white';
            document.getElementById('menu5').style.borderColor = 'black';
        }

        else if(key === 4) {
            document.getElementById('cursor').style.left = '54%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
            document.getElementById('menu5').style.borderColor = 'white';
        }
    }
}

export function cursorMove() {
        var d = document.getElementById('cursor');
        d.style.top = (2.4 * key) + 'vh';
}

export function closeScreen() {
    var screenOff = document.getElementById("screenIsOff");
    var screenOn = document.getElementById("screenIsOn");

    if (count === 1) {
        if (!localStorage.getItem('visitedBefore')) {
            // Display the first Clippy image on the first visit
            document.getElementById('clippy1').style.display = 'none';
            document.getElementById('clippy2').style.display = 'block';
        }
    }

    else {
        count++;
    }

    if (screen === 1) {
        screenOn.style.display = "none";
        screenOff.style.display = "block";
        document.getElementById("powerbuttonled").style.backgroundColor = "red";
        document.getElementById("powerbuttonled").style.boxShadow = "0px 0px 5px 2.5px red";
        screen = 0;
    }

    else {
        glitchCount++;

        if (glitchCount > 3) {
            glitch();
            glitchCount = 0;
        }
        else {}

        document.getElementById("powerbuttonled").style.backgroundColor = "#0e8f15";
        document.getElementById("powerbuttonled").style.boxShadow = "0px 0px 5px 2.5px #109e17";
        screenOff.style.display = "none";
        screenOn.style.display = "block";
        screen = 1;
    }

    document.getElementById("powerbutton").style.border = "outset";
    setTimeout(() => {
        document.getElementById("powerbutton").style.border = "inset";
    }, 400);
}

export function glitch() {
    document.getElementById("linkBlocker").style.display = "block";
    document.getElementById("aboutText").style.display = "none";
    document.getElementById("aboutText2").style.display = "block";
    document.getElementById("aboutText3").style.display = "block";
    setTimeout(() => {
        document.getElementById("linkBlocker").style.display = "none";
        document.getElementById("aboutText").style.display = "block";
        document.getElementById("aboutText2").style.display = "none";
        document.getElementById("aboutText3").style.display = "none";
    }, 4000);
}

export function triggerEvaporateEffect() {
    // Hide and animate Clippy 3
    const clippy = document.getElementById('clippy3');
    clippy.style.animation = 'violentHover 1s ease-in-out 5';
    clippy.style.transition = 'transform 10s'; // Adjust timing for slower fade
    clippy.style.transform = 'scale(0)';
  
    // Create and append particle elements to the DOM
    const numParticles = 100; // Total number of particles
    const animationDuration = 5; // Total animation duration in seconds
    const spawnInterval = (animationDuration * 1000) / numParticles; // Calculate spawn interval
  
    let particleIndex = 0; // Initialize particle index
  
    // Function to spawn particles at intervals
    function spawnParticle() {
      if (particleIndex < numParticles) {
        const particle = document.createElement('div');
        particle.className = 'particle';
        particle.innerText = english[Math.floor(Math.random() * english.length)]; // Assign a random character
        particle.style.top = '0'; // Initial top position
        particle.style.left = Math.random() * 100 + '%'; // Random left position
  
        // Randomize animation duration for each particle
        particle.style.animationDuration = `${Math.random() * 2 + 1}s`; // Adjust range as needed
  
        document.getElementById('clippy3').appendChild(particle);
        particleIndex++;
  
        // Add an event listener to remove particles when the animation ends
        particle.addEventListener('animationend', () => {
          particle.remove();
        });
  
        // Call the function recursively with a delay
        setTimeout(spawnParticle, spawnInterval);
      }
    }
  
    // Start spawning particles
    spawnParticle();
}

export function flashWindows(times) {
    const flashElements = ['streamPageApp', 'storePageApp', 'aboutPageApp', 'resourcesApp', 'contactPageApp'];
    
    // Function to shuffle an array
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const flashCycle = (cycle) => {
        if (cycle > times) return; // Stop when the desired number of cycles is reached

        // Shuffle the elements array for random order
        const shuffledElements = shuffleArray(flashElements);

        shuffledElements.forEach((id, index) => {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.style.display = 'block';
                    setTimeout(() => {
                        element.style.display = 'none';
                    }, 200); // Hide after 300 milliseconds
                }
            }, index * 200); // Stagger the flash start times by 200 milliseconds
        });

        // Call flashCycle again for the next cycle
        setTimeout(() => {
            flashCycle(cycle + 1);
        }, flashElements.length * 200 + 200); // Total time for one flash cycle
    };

    flashCycle(1); // Start the first cycle
}
