import React, { Component } from 'react';

class Resources extends Component {
    constructor(props){
        super(props);

		this.bringToFront = this.bringToFront.bind(this);
        this.closeApp = this.closeApp.bind(this);
    }

	bringToFront() {
		document.getElementById('cmdApp').style.zIndex = 1;
		document.getElementById('streamPageApp').style.zIndex = 1;
		document.getElementById('storePageApp').style.zIndex = 1;
		document.getElementById('aboutPageApp').style.zIndex = 1;
        document.getElementById('resourcesApp').style.zIndex = 2;
		document.getElementById('contactPageApp').style.zIndex = 1;
	}

    closeApp() {
		document.getElementById('resourcesApp').style.zIndex = 1;
        document.getElementById('resourcesApp').style.display = 'none';
    }

    render() { 
        return (
            <div id='resourcesApp' onClick={this.bringToFront}>
                <div id="cmdtitlearea">
					<div id="resourcePage-title"> 
						<b> Resources </b>
					</div>
                    <div className='closeButton' onClick={this.closeApp}>X</div>
				</div>
                <div id="resourceList">
                    <ul class="resource-list">
                        <li>
                            <a href="https://leetcode.com/">https://leetcode.com/</a> - Learn how to program
                        </li>
                        <li>
                            <a href="https://www.hackthebox.com/">https://www.hackthebox.com/</a> - Learn how to hack
                        </li>
                        <li>
                            <a href="https://book.hacktricks.xyz/welcome/readme">https://book.hacktricks.xyz/</a> - Hacking resource
                        </li>
                        <li>
                            <a href="https://virgilabloh.com/free-game/">https://virgilabloh.com/free-game/</a> - Start a brand
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
 
export default Resources;

